"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_MONITORING_QUESTION_STATUS = exports.UPSERT_MONITORING_QUESTION_ANSWER = exports.DELETE_MONITORING_CATEGORY = exports.UPSERT_MONITORING_CATEGORY = void 0;
var client_1 = require("@apollo/client");
exports.UPSERT_MONITORING_CATEGORY = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation upsertMonitoring($input: [MonitoringCategoryInput]!) {\n    upsertMonitoring(input: $input)\n  }\n"], ["\n  mutation upsertMonitoring($input: [MonitoringCategoryInput]!) {\n    upsertMonitoring(input: $input)\n  }\n"])));
exports.DELETE_MONITORING_CATEGORY = (0, client_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  mutation deleteMonitoringCategory($id: ID!, $organization_id: ID!) {\n    deleteMonitoringCategory(id: $id, organization_id: $organization_id)\n  }\n"], ["\n  mutation deleteMonitoringCategory($id: ID!, $organization_id: ID!) {\n    deleteMonitoringCategory(id: $id, organization_id: $organization_id)\n  }\n"])));
exports.UPSERT_MONITORING_QUESTION_ANSWER = (0, client_1.gql)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  mutation upsertMonitoringQuestionAnswer($input: [QuestionPayload!]!) {\n    upsertMonitoringQuestionAnswer(input: $input)\n  }\n"], ["\n  mutation upsertMonitoringQuestionAnswer($input: [QuestionPayload!]!) {\n    upsertMonitoringQuestionAnswer(input: $input)\n  }\n"])));
exports.UPDATE_MONITORING_QUESTION_STATUS = (0, client_1.gql)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  mutation updateMonitoringQuestionStatus(\n    $id: ID!\n    $published: Boolean\n    $organization_id: ID!\n  ) {\n    updateMonitoringQuestionStatus(\n      id: $id\n      published: $published\n      organization_id: $organization_id\n    )\n  }\n"], ["\n  mutation updateMonitoringQuestionStatus(\n    $id: ID!\n    $published: Boolean\n    $organization_id: ID!\n  ) {\n    updateMonitoringQuestionStatus(\n      id: $id\n      published: $published\n      organization_id: $organization_id\n    )\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
