"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function isEmpty(obj) {
    for (var key in obj) {
        // if (obj.hasOwnProperty(key)) {
        //     return false;
        // }
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            return false;
        }
    }
    return true;
}
exports.default = isEmpty;
