"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_MONITORING_QUESTION_WITH_ANSWER = exports.GET_MONITORING_CATEGORY = void 0;
var client_1 = require("@apollo/client");
exports.GET_MONITORING_CATEGORY = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query getMonitoringCategory {\n    getMonitoringCategory {\n      id\n      name\n      organization_id\n      sorting\n      component_name\n    }\n  }\n"], ["\n  query getMonitoringCategory {\n    getMonitoringCategory {\n      id\n      name\n      organization_id\n      sorting\n      component_name\n    }\n  }\n"])));
exports.GET_MONITORING_QUESTION_WITH_ANSWER = (0, client_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  query getQuestionWithAnswer(\n    $organization_id: ID!\n    $monitoring_category_id: ID\n    $published: Boolean\n  ) {\n    getQuestionWithAnswer(\n      organization_id: $organization_id\n      monitoring_category_id: $monitoring_category_id\n      published: $published\n    ) {\n      id\n      name\n      organization_id\n      published\n      order_by\n      monitoring_category_id\n      data_type_id\n      monitoring_anwers {\n        id\n        name\n        sorting\n        monitoring_question_id\n      }\n      data_type {\n        id\n        name\n      }\n      audio_link\n      web_audio_link\n      dependent_id\n    }\n  }\n"], ["\n  query getQuestionWithAnswer(\n    $organization_id: ID!\n    $monitoring_category_id: ID\n    $published: Boolean\n  ) {\n    getQuestionWithAnswer(\n      organization_id: $organization_id\n      monitoring_category_id: $monitoring_category_id\n      published: $published\n    ) {\n      id\n      name\n      organization_id\n      published\n      order_by\n      monitoring_category_id\n      data_type_id\n      monitoring_anwers {\n        id\n        name\n        sorting\n        monitoring_question_id\n      }\n      data_type {\n        id\n        name\n      }\n      audio_link\n      web_audio_link\n      dependent_id\n    }\n  }\n"])));
var templateObject_1, templateObject_2;
