"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
require("./style.sass");
var Checkbox = function (_a) {
    var checkValue = _a.checkValue, error = _a.error, label = _a.label, list = _a.list, onCheckValueChange = _a.onCheckValueChange, single = _a.single;
    var _b = (0, react_1.useState)(new Map()), checkedItems = _b[0], setCheckedItems = _b[1];
    var _c = (0, react_1.useState)(true), singleCheckedItem = _c[0], setSingleCheckeditem = _c[1];
    (0, react_1.useEffect)(function () {
        if (Array.isArray(list) && !single) {
            var newChecked_1 = new Map();
            checkValue.map(function (i) {
                newChecked_1.set(i, true);
            });
            setCheckedItems(newChecked_1);
        }
        else {
            setSingleCheckeditem(checkValue);
        }
    }, [checkValue, list, single]);
    return (react_1.default.createElement("div", { style: {
            margin: "0.3125rem",
            display: "flex",
            flex: 1,
            width: "100%",
            flexDirection: "column",
        } },
        error && react_1.default.createElement("p", { className: "error" }, error),
        react_1.default.createElement("div", { className: "checkbox-container" },
            react_1.default.createElement("label", { className: "label" }, label),
            !single &&
                Array.isArray(list) &&
                list.length > 0 &&
                list.map(function (item) {
                    return (react_1.default.createElement("div", { className: "checkbox-item", key: item.id },
                        react_1.default.createElement("label", null,
                            react_1.default.createElement("input", { checked: Boolean(checkedItems.get(item.id)), className: "css-checkbox", name: item.id, onChange: function (e) {
                                    var newChecked = new Map(checkedItems);
                                    var item = e.target.name, isChecked = e.target.checked;
                                    newChecked.set(item, isChecked);
                                    setCheckedItems(newChecked);
                                    // changedCheckBox(newChecked);
                                    onCheckValueChange(newChecked);
                                }, type: "checkbox" }),
                            react_1.default.createElement("i", null)),
                        react_1.default.createElement("a", { className: "checkbox-label" }, item.name)));
                }),
            single && (react_1.default.createElement("div", { className: "checkbox-item" },
                react_1.default.createElement("label", null,
                    react_1.default.createElement("input", { checked: singleCheckedItem, className: "css-checkbox", name: list === null || list === void 0 ? void 0 : list.id, onChange: function () {
                            setSingleCheckeditem(!singleCheckedItem);
                            // changedCheckBox(!singleCheckedItem);
                            onCheckValueChange(!singleCheckedItem);
                        }, type: "checkbox" }),
                    react_1.default.createElement("i", null)))))));
};
exports.default = Checkbox;
