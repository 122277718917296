"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuditorRoutes = exports.SuperAdminRoutes = exports.AdminRoutes = exports.ClientRoutes = exports.NonAuthRoutes = exports.AuthRoutes = void 0;
var AuthRoutes;
(function (AuthRoutes) {
    // dashboard = "/dashboard",
    AuthRoutes["organization"] = "/organization";
})(AuthRoutes = exports.AuthRoutes || (exports.AuthRoutes = {}));
var NonAuthRoutes;
(function (NonAuthRoutes) {
    NonAuthRoutes["login"] = "/";
    NonAuthRoutes["unauthorized"] = "/unauthorized";
})(NonAuthRoutes = exports.NonAuthRoutes || (exports.NonAuthRoutes = {}));
var ClientRoutes;
(function (ClientRoutes) {
    ClientRoutes["dashboard"] = "/agent/dashboard";
    ClientRoutes["usage"] = "/agent/usage";
    ClientRoutes["education"] = "/agent/education";
    ClientRoutes["service"] = "/agent/service";
    ClientRoutes["underConstruction"] = "/agent/underConstruction";
})(ClientRoutes = exports.ClientRoutes || (exports.ClientRoutes = {}));
var AdminRoutes;
(function (AdminRoutes) {
    AdminRoutes["organization"] = "/admin/organization";
    AdminRoutes["dashboard"] = "/admin/dashboard";
    AdminRoutes["underConstruction"] = "/admin/underConstruction";
    AdminRoutes["usage"] = "/admin/usage";
    AdminRoutes["education"] = "/admin/education";
    AdminRoutes["education_video"] = "/admin/education/vidoes";
    AdminRoutes["education_question"] = "/admin/education/questions";
    AdminRoutes["serviceMain"] = "/admin/service_center";
    AdminRoutes["service"] = "/admin/service";
    AdminRoutes["report"] = "/admin/report";
    AdminRoutes["generalReport"] = "/admin/General";
    AdminRoutes["guide"] = "/admin/guide";
    AdminRoutes["monitoring"] = "/admin/monitoring";
    // monitoring = "admin/underConstruction",
})(AdminRoutes = exports.AdminRoutes || (exports.AdminRoutes = {}));
var SuperAdminRoutes;
(function (SuperAdminRoutes) {
    SuperAdminRoutes["dashboard"] = "/superadmin/dashboard";
    SuperAdminRoutes["underConstruction"] = "/superadmin/underConstruction";
    SuperAdminRoutes["usage"] = "/superadmin/usage";
    SuperAdminRoutes["education"] = "/superadmin/education";
    SuperAdminRoutes["education_video"] = "/superadmin/education/vidoes";
    SuperAdminRoutes["education_question"] = "/superadmin/education/questions";
    SuperAdminRoutes["service"] = "/superadmin/service";
    SuperAdminRoutes["report"] = "/superadmin/report";
    SuperAdminRoutes["generalReport"] = "/superadmin/General";
    SuperAdminRoutes["guide"] = "/superadmin/guide";
    SuperAdminRoutes["add_service_category"] = "/superadmin/serviceCategory";
})(SuperAdminRoutes = exports.SuperAdminRoutes || (exports.SuperAdminRoutes = {}));
var AuditorRoutes;
(function (AuditorRoutes) {
    AuditorRoutes["organization"] = "/auditor/organization";
    AuditorRoutes["dashboard"] = "/auditor/dashboard";
    AuditorRoutes["underConstruction"] = "/auditor/underConstruction";
    AuditorRoutes["usage"] = "/auditor/usage";
    AuditorRoutes["education"] = "/auditor/education";
    AuditorRoutes["education_video"] = "/auditor/education/vidoes";
    AuditorRoutes["education_question"] = "/auditor/education/questions";
    AuditorRoutes["serviceMain"] = "/auditor/service_center";
    AuditorRoutes["service"] = "/auditor/service";
    AuditorRoutes["report"] = "/auditor/report";
    AuditorRoutes["generalReport"] = "/auditor/General";
    AuditorRoutes["guide"] = "/auditor/guide";
    AuditorRoutes["monitoring"] = "/auditor/monitoring";
    // monitoring = "/auditor/underConstruction"
})(AuditorRoutes = exports.AuditorRoutes || (exports.AuditorRoutes = {}));
