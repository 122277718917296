"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.general_report_list = exports.correct_list = exports.child_list = void 0;
var child_list = [
    {
        name: "video",
    },
    { name: "question" },
];
exports.child_list = child_list;
var correct_list = [
    {
        id: true,
        value: true,
        label: '{"en":"Yes","fr":"Oui","rn":"Ego"}',
    },
    {
        id: false,
        value: false,
        label: '{"en":"No","fr":"Non","rn":"Nomero"}',
    },
];
exports.correct_list = correct_list;
var general_report_list = [
    { name: "App Installation Count" },
    { name: "Synchronization" },
];
exports.general_report_list = general_report_list;
