"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidateGuideForm = void 0;
var ValidateGuideForm = function (payload, currentLanguage) {
    if (!payload.category) {
        return {
            message: "required",
            field: "category",
            status: "error",
        };
    }
    else if (JSON.parse(payload.title)[currentLanguage] === "") {
        return {
            message: "required",
            field: "title",
            status: "error",
        };
    }
    return {
        message: "success",
        field: "",
        status: "success",
    };
};
exports.ValidateGuideForm = ValidateGuideForm;
