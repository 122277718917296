"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidateService = void 0;
var ValidateService = function (payload, current_language) {
    var parsedName = JSON.parse(payload.name);
    var checkValue = false;
    Object.keys(parsedName).forEach(function (key) {
        if (parsedName[key] !== "") {
            checkValue = true;
        }
    });
    if (!checkValue) {
        return {
            message: "required",
            field: "name",
            status: "error",
        };
    }
    else if (payload.description === "") {
        return {
            message: "required",
            field: "description",
            status: "error",
        };
    }
    else if (!payload.service_category) {
        return {
            message: "required",
            field: "category",
            status: "error",
        };
    }
    else {
        return {
            message: "success",
            field: "",
            status: "success",
        };
    }
};
exports.ValidateService = ValidateService;
