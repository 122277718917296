"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPSERT_GUIDE = exports.DELETE_GUIDE = exports.UPSERT_GUIDE_CATEGORY = exports.DELETE_GUIDE_CATEGORY = void 0;
var client_1 = require("@apollo/client");
var DELETE_GUIDE_CATEGORY = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation deleteGuideCategory($id: ID!, $organization_id: ID!) {\n    deleteGuideCategory(id: $id, organization_id: $organization_id)\n  }\n"], ["\n  mutation deleteGuideCategory($id: ID!, $organization_id: ID!) {\n    deleteGuideCategory(id: $id, organization_id: $organization_id)\n  }\n"])));
exports.DELETE_GUIDE_CATEGORY = DELETE_GUIDE_CATEGORY;
var UPSERT_GUIDE_CATEGORY = (0, client_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  mutation upsertGuideCategory($input: [guidePayload!]!) {\n    upsertGuideCategory(input: $input)\n  }\n"], ["\n  mutation upsertGuideCategory($input: [guidePayload!]!) {\n    upsertGuideCategory(input: $input)\n  }\n"])));
exports.UPSERT_GUIDE_CATEGORY = UPSERT_GUIDE_CATEGORY;
var DELETE_GUIDE = (0, client_1.gql)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  mutation deleteGuide(\n    $organization_id: ID!\n    $guide_category_id: ID\n    $id: ID!\n  ) {\n    deleteGuide(\n      id: $id\n      organization_id: $organization_id\n      guide_category_id: $guide_category_id\n    )\n  }\n"], ["\n  mutation deleteGuide(\n    $organization_id: ID!\n    $guide_category_id: ID\n    $id: ID!\n  ) {\n    deleteGuide(\n      id: $id\n      organization_id: $organization_id\n      guide_category_id: $guide_category_id\n    )\n  }\n"])));
exports.DELETE_GUIDE = DELETE_GUIDE;
var UPSERT_GUIDE = (0, client_1.gql)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  mutation upsertGuide(\n    $guide_id: ID\n    $title: String!\n    $description: String\n    $sorting: Int\n    $guide_category_id: ID!\n    $guide_media: GuideMediaInput!\n    $organization_id: ID!\n  ) {\n    upsertGuide(\n      guide_id: $guide_id\n      title: $title\n      description: $description\n      sorting: $sorting\n      guide_category_id: $guide_category_id\n      guide_media: $guide_media\n      organization_id: $organization_id\n    )\n  }\n"], ["\n  mutation upsertGuide(\n    $guide_id: ID\n    $title: String!\n    $description: String\n    $sorting: Int\n    $guide_category_id: ID!\n    $guide_media: GuideMediaInput!\n    $organization_id: ID!\n  ) {\n    upsertGuide(\n      guide_id: $guide_id\n      title: $title\n      description: $description\n      sorting: $sorting\n      guide_category_id: $guide_category_id\n      guide_media: $guide_media\n      organization_id: $organization_id\n    )\n  }\n"])));
exports.UPSERT_GUIDE = UPSERT_GUIDE;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
