"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Config = {
    app_url: process.env.MIX_REACT_APP_URL,
    app_url_graphql: process.env.MIX_REACT_APP_URL_GRAPHQL,
    translation_url: process.env.MIX_REACT_TRANSLATION_API_URL,
    app_name: process.env.MIX_REACT_APP_NAME,
    minio_endpoint: process.env.MIX_REACT_MINIO_ENDPOINT,
    aws_secret: process.env.MIX_REACT_MINIO_AWS_SECRET,
    aws_key: process.env.MIX_REACT_MINIO_AWS_KEY,
    aws_region: process.env.MIX_REACT_MINIO_AWS_REGION,
    aws_bucket: process.env.MIX_REACT_MINIO_AWS_BUCKET,
};
exports.default = Config;
